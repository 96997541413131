@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'LarkenDEMO-Black';
  src: url('fonts/LarkenDEMO-Black.otf') format('opentype');
  font-weight: 400;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, 'SF UI Display', sans-serif;
}
.ratingStar {
  margin-top: 0.625rem;
}
.ratingStar label {
  color: #4426ff;
  display: flex;
  align-items: end;
  justify-content: center;
}
.ratingStar label:first-child,
label:nth-child(9) {
  font-size: 15px;
}
.ratingStar label:nth-child(3),
label:nth-child(7) {
  font-size: 20px;
}

.heading-1 {
  font-family: 'LarkenDEMO-Black';
  font-size: 4.5rem;
  line-height: 99px;
}
.postTime {
  font-family: 'Roboto';
  font-weight: 400;
  opacity: 0.6;
  font-size: 0.75rem;
  padding-top: 1.25rem;
  padding-bottom: 1.625rem;
}
p,
.bonusText,
.minDeposit,
.ratingText,
.heading,
.minDepositHeader,
.bonusHeader,
.ratingHeader {
  font-family: 'Georgia';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}
.bonusHeader {
  margin-top: 15px;
  margin-bottom: 10px;
}
.minDeposit {
  font-size: 1.25rem;
  color: #4426ff;
}
.ratingText {
  color: #000000;
}
.heading {
  margin-bottom: 1.25rem;
  color: #000000;
  padding-right: 0.563rem;
}
.heading,
.brandRow {
  display: grid;
  grid-template-columns: 150px 200px 175px 140px 150px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-bottom: 1.875rem;
}

.brandRow {
  background-color: #ffffff;
  box-shadow: 0px 12px 100px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  padding-right: 0.563rem;
}

.heading li {
  list-style: none;
}
.brandWrapper {
  margin-top: 3.5rem;
}
.brandImg {
  padding-left: 0.825rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.btnPlay {
  font-family: 'SF UI Display';

  padding: 0.688rem 2rem;
  background: #4426ff;
  border-radius: 6px;
  color: white;
  text-decoration: none;
}
.footer {
  display: flex;
  justify-content: end;
  padding: 2rem;
}
.footer li {
  list-style: none;
  margin-left: 1rem;
}
.footer a {
  text-decoration: none;
}
.disclaimer {
  margin-top: 2rem;
  font-size: 12px;
}
.brandRowMob {
  display: none;
}
@media only screen and (max-width: 900px) {
  .heading {
    display: none;
  }
  .brandRow {
    display: none;
  }
  .brandRowMob {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 12px 100px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    padding: 10px;
  }
  .brandRowMob a {
    text-align: center;
  }
  .brandImg {
    padding: 0;
  }
  .brandwrapperMob,
  .bonusWrapperMob,
  .rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .heading-1 {
    font-size: 2rem;
    line-height: 50px;
  }
  .minDepositHeader {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .minDeposit {
    margin-bottom: 37px;
  }
  .bonusText {
    color: #4426ff;
  }
  .ratingHeader {
    margin-top: 20px;
    margin-bottom: 5px;
  }
}
.button {
  background-color: #004a7f;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}
